import React from "react";
import { Gap } from "../../styles/login.styles";
import { auth } from "../../utils/firebase";
import { useFormik } from "formik";
import { toast } from "react-toastify";

const ForgotPassForm = () => {
  const validate = (values) => {
    let errors = {};
    if (!values.email) {
      errors.email = "⋆Required";
    } else if (
      !/^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,4}$/i.test(values.email)
    ) {
      errors.email = "Invalid email format";
    }
    return errors;
  };

  const initialValues = {
    email: "",
  };

  const onSubmit = async (values, onSubmitProps) => {
    console.log("submit form");
    auth
      .sendPasswordResetEmail(values.email)
      .then(() => {
        console.log("success");
        onSubmitProps.resetForm();
        toast.success("A reset email has been sent!");
      })
      .catch((err) => {
        toast.error("Some error occured, please try again");
        console.log(err);
      });
  };

  const formik = useFormik({
    initialValues,
    onSubmit,
    validate,
  });

  return (
    <>
      <div>
        <label htmlFor="email">Email</label>
        <input
          className={"input-success"}
          type="email"
          name="email"
          value={formik.values.email}
          onChange={formik.handleChange}
          onBlur={formik.handleBlur}
        />
        {formik.touched.email && formik.errors.email ? (
          <div className="error-msg">{formik.errors.email}</div>
        ) : null}
      </div>

      <Gap mt={"14px"}></Gap>

      <button
        className="button-filled"
        disabled={!(formik.isValid && formik.dirty)}
        type="submit"
        onClick={formik.submitForm}
      >
        Submit
      </button>
    </>
  );
};

export default ForgotPassForm;
