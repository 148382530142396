import React from "react";
import { Wrapper } from "../components/globalElements";
import Layout from "../components/Layout/Layout";
import { TextSplit } from "components";
import { LoginStyled } from "../styles/login.styles";
import ForgotPassForm from "../components/Onboard/ForgotPassForm";
import OnboardFooter from "../components/Onboard/OnboardFooter";

const ForgotPassword = () => {
  return (
    <Layout title="Forgot Password" fullScreen={true} footer={false}>
      <Wrapper style={{ marginBottom: "5rem" }}>
        <TextSplit>
          <LoginStyled>
            <h1 className="heading">Forgot Password</h1>
            <p className="paragraph">
              To reset your password, please enter your email address. We will
              send the password reset instructions to the email address for this
              account.
            </p>

            <ForgotPassForm />
            <div className="middle-line"></div>

            <OnboardFooter />
          </LoginStyled>
        </TextSplit>
      </Wrapper>
    </Layout>
  );
};

export default ForgotPassword;
